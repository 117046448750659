import Joi from 'joi-browser';
import React from 'react';
import { Link } from 'react-router-dom';
import Form from '../common/form';
import Spinner from '../common/spinner';

class LoginForm extends Form {
	state = {
		data: { email: this.props.email || '', password: '' },
		errors: {},
	};

	schema = {
		email: Joi.string().email().required().label('Email'),
		password: Joi.string().required().min(8).max(26).label('Password'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading } = this.props;
		return (
			<form onSubmit={this.handleSubmit}>
				<div className='mb-3'>
					{this.renderInput({
						name: 'email',
						label: 'Email',
						type: 'email',
					})}
				</div>
				<div className='mb-3'>
					{this.renderInput({
						name: 'password',
						label: 'Password',
						type: 'password',
						formText: (
							<div className='d-flex justify-content-end'>
								<Link className='me-3' to={'/passwordReset'}>
									Forgot Password ?
								</Link>
							</div>
						),
					})}
				</div>
				<div className='text-center'>
					{this.renderButton(
						'Login',
						`btn btn-lg btn-my-navy`,
						isLoading && <Spinner content={'Loging in...'} />
					)}
				</div>
			</form>
		);
	}
}

export default LoginForm;
